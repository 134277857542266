import React from "react";
import _ from "lodash";
import TechPage from '../components/TechPage'
import Icon from '../components/Icon';
import digital from '../media/icons/new/1-digital.svg';
import IllustrationVisual from '../media/illustration/Visual.png';

export default class TabPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 'transformation-digitale',
      title: "Transformation digitale en santé",
      description: "Que ce soit en R&D, dans l'optimisation des tâches à l'hôpital ou pour le développement de nouveaux médicaments, la transformation digitale en santé demande des compétences particulières.",
      content:
        <div class="columns section-panel">
          <div class="column alt-column is-8">
            <div class="content is-medium">
              <p>Pour les établissements de soin, suivre le rythme rapide de la transformation numérique peut sembler accablant. Il est vraiment difficile de choisir les <strong>processus commerciaux à moderniser</strong> et les <strong>technologies « émergentes »</strong> dans lesquelles investir tout en veillant à ce qu'il n'y ait pas de <strong>perturbation significative</strong> des activités. Akimed peut vous aider à surmonter ces défis.</p>
              <p>La numérisation et la mobilisation des processus peuvent rapidement générer un <strong>retour sur investissement</strong>. Les initiatives de transformation numérique réussies telles que les <strong>soins à la demande</strong>, l'utilisation d'analyses pour réduire les <strong>erreurs médicamenteuses</strong> et l'utilisation de la technologie pour <strong>personnaliser l'expérience des soins</strong> donnent la priorité aux patients et améliorent considérablement les résultats en permettant des décisions de soins plus rapides et plus précises.</p>
              <p>Akimed est <strong>votre partenaire de changement</strong> dans la continuité de vos initiatives d’innovation de la stratégie de transformation digitale à la numérisation des processus métiers.</p>
            </div>
          </div>
          <div class="column alt-column is-4">
            <div class="content is-medium">
              <figure class="image">
                <Icon src={IllustrationVisual} />
              </figure>
            </div>
          </div>
        </div>
    };
  }

  render() {
    return (
      <TechPage 
        content={this.state.content} 
        activePage={this.state.page} 
        title={this.state.title}
        description={this.state.description}
      />
    );
  }
}
