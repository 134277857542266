import React from "react"
import Page from "../layouts/Page"
import _ from "lodash"


export default class TechPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      layout: (typeof window !== "undefined" && window.LAYOUT) || "grid"
    };
  }

  render() {
    return (
      <Page title={this.props.title} description={this.props.description}>
        <section className="hero first-hero is-primary">
          <div className="hero-body">
            <div className="container" style={{maxWidth: "1280px"}}>
              <h1 className="title">Applications et technologies</h1>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container" style={{maxWidth: "1280px"}}>
            <div class="tabs is-large">
              <ul>
                <li class={ this.props.activePage==='transformation-digitale' ? "is-active":"" }>
                  <a href="/transformation-digitale/">Transformation digitale</a>
                </li>
                <li class={ this.props.activePage==='interoperability' ? "is-active":"" }>
                  <a href="/interoperability/">Interopérabilité</a>
                </li>
                <li class={ this.props.activePage==='real-world-data' ? "is-active":"" }>
                  <a href="/real-world-data/">Données de vie réelle</a>
                </li>
              </ul>
            </div>
            { this.props.content }
          </div>
        </section>
        { this.props.articles }
      </Page>
    );
  }
}
